<script setup lang="ts">
   const { isClientAuthenticated } = useAuthClientStorage();
   import { useRoute } from 'vue-router';
   import { DefaultAssets } from '~/assets/DefaultAssets';
   const { logoutAsClient } = useClientAuthStore();
   const { t } = useI18n();
   const { name } = useRoute();
   const logOut = () => {
      logoutAsClient();
   };
   const { isClient } = storeToRefs(useClientInfo());
</script>
<template>
   <nav class="flex justify-between h-20">
      <div class="flex justify-between items-center w-full">
         <div>
            <img
               :src="DefaultAssets.images.logo"
               class="w-36 md:w-40"
               :alt="`TalkTime Logo `"
            />
         </div>
         <div class="flex items-center gap-2">
            <LayoutLanguageSelect />
            <div v-if="isClientAuthenticated">
               <BaseButton size="sm" color="primary-500" @click="logOut">
                  {{ t('Logout') }}
               </BaseButton>
            </div>
         </div>
      </div>
   </nav>
</template>

<
